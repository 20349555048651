import * as React from "react";

import SEO from "../components/seo";

const NotFoundPage = () => (
    <section>
        <SEO title="404: Not found"/>
        <p>Nothing to see here.</p>
    </section>
);

export default NotFoundPage;
